<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Class</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    label="Select Classes"
                    :items="classes"
                    :item-text="'name'"
                    v-model="formData.schoolClass"
                    return-object
                    required
                    :rules="isValid.name"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Description"
                    v-model="formData.description"
                    required
                    :rules="isValid.description"
                  ></v-text-field>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <DualSelects
                    :items="streams"
                    :title="'Select Streams'"
                    :label="'Filter Streams...'"
                    :columnName="'name'"
                    :filter-function="searchOptions"
                    v-model="formData.streams"
                    :is-open="dialog"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },

    searchOptions: {
      type: Function,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    streams: {
      type: Array,
      required: true,
    },
    classes: {
      type: Array,
      required: true,
    },
  },

  name: "SchoolClassesDialog",
  data: () => ({
    valid: true,
    isValid: {
      name: [
        (v) => !!v || " Name required",
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
      description: [(v) => !!v || " Name required"],
      level: [(v) => !!v || " Name required"],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData };

      data.streamsIds = this.formData.streams
        ? this.formData.streams.map((stream) => stream.id)
        : [];
      //console.log("payload", data);
      this.save(data);
    },

    close() {
      this.closeDialog();
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
  },
  mounted() {},
};
</script>
